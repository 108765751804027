* {
  margin: 0;
  padding: 0;
}

header {
  position: fixed;
  top: 0px;
  width: 95%;
  display: flex;
  justify-content: space-between;
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 10px 50px;
}

header nav {
  display: flex;
  text-align: center;
}

header nav a {
  margin: auto 0;
  padding: 5px 15px;
}

header nav div {
  color: white;
  cursor: pointer;
}

.main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  margin-bottom: 50px;
  text-align: center;
}

.picture {
  background-size: cover;
  height: 500px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.pic1 {
  background-image: url('./images/IMG_0842.JPG');
}

.pic2 {
  background-image: url('./images/IMG_2389.JPG');
}

.pic3 {
  background-image: url('./images/IMG_2392.JPG');
}

.picture h1 {
  font-size: 70px;
  -webkit-text-stroke: 2px black;
}

.text {
  margin: 10px 200px;
}

.text h2,
.text h3,
.text p {
  margin-bottom: 10px;
}

.text p {
  font-size: 18px;
}

.link-box {
  width: 50%;
  margin: 0 auto;
}

.new-plot-link {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
}

.loc-pic {
  width: 75%;
  margin-top: 10px;
}

.text h2 {
  margin-top: 20px;
}

.contact-box {
  width: 350px;
  margin: 0 auto;
}

.text-left {
  text-align: left;
}

footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: rgb(39, 39, 39);
  color: white;
  padding: 20px 0;
  line-height: 1.5;
}

.hidden {
  display: none;
}
